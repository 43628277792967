import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LanguageService } from '@core/language/language.service';
import { EMPTY, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// to-do reconfigure this class to become a route guard
// resolver is to resolve route data not for redirection
export class LanguageRedirectResolver implements Resolve<boolean> {
  constructor(
    private router: Router,
    private languageService: LanguageService,
  ) {}

  // this is executed only if the current selected language is es or fr
  // and the url has an empty path i.e. path: '' or '/'
  // we redirect the navigation to the current language home route
  // example '/es' or '/fr'
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    // current selected language
    const lang = this.languageService.getLanguage();

    // for english the home route is '/'
    // we do not need to redirect
    if (lang === 'en') {
      return of(true);
    }

    // points to es or fr home page e.g. /es or /fr
    const redirectRoute = '/' + lang;

    // Redirect to the appropriate home route
    this.router.navigate([redirectRoute], { replaceUrl: true });
    return EMPTY;
  }
}
