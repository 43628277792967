import { Injectable } from '@angular/core';
import { Route, Routes, UrlTree } from '@angular/router';
import { AppConfigService } from '@core/app-config';

import { LanguageGuard } from './language.guard';
import { LanguageRedirectResolver } from './language-redirect.resolver';

// made it angular service to improve tests
@Injectable({
  providedIn: 'root',
})
export class RouterUtilService {
  // this is invoked when we init the router or when we switchLanguage from the footer
  // to-do: the cmsRoutes path already come prefixed with a language
  // generateRoutes should only run on router init and replace the path ':lang' with specific path
  // for each lang like path:'es' and its children would be the routes from cms prefixed with that lang
  generateRoutes(
    cmsRoutes: Routes,
    baseRoutes: Routes, // hard coded routes
    appConfig: AppConfigService,
    language: string = 'en', // current selected language
    devRoutes: Routes = [], // Added this as an argument since it was in your original code
    forceEn: boolean = false,
  ) {
    let combinedRoutes: Routes = [
      ...this.addChildRoutes(
        this.determineAppConfigPanelVisibility(baseRoutes, appConfig),
        cmsRoutes,
      ),
      {
        path: '**',
        loadChildren: () =>
          import('@feature/not-found-page/not-found-page.module').then(
            m => m.NotFoundPageModule,
          ),
      },
    ];

    if (!appConfig.isProdEnv && devRoutes.length > 0) {
      combinedRoutes = [...devRoutes, ...combinedRoutes];
    }

    // removes any routes that we explicitly do not want to have a language prefix
    // matches partial ignore route values as well (eg, /checkin matches /checkin/check-in
    // to-do: this should be executed only if language is not english
    const languageUrlRoutesWithoutIgnoredRoutes = combinedRoutes.map(route => {
      if (
        route.children &&
        route.path === '' &&
        !!appConfig.ignoreRoutesForLanguageInUrl.length
      ) {
        return {
          ...route,
          children: route.children.filter(
            childRoute =>
              !appConfig.ignoreRoutesForLanguageInUrl.some(ignoreRoute =>
                childRoute.path.includes(ignoreRoute),
              ),
          ),
        };
      }
      return route;
    });

    // to-do: this should be executed only if language is not english
    const routesThatWereIgnored =
      (!!appConfig.ignoreRoutesForLanguageInUrl.length &&
        combinedRoutes
          .find(route => route.path === '')
          ?.children?.filter(route =>
            appConfig.ignoreRoutesForLanguageInUrl.some(ignoreRoute =>
              route.path.includes(ignoreRoute),
            ),
          )) ||
      [];

    return language !== 'en'
      ? ([
          // if selected lang is not english return this
          {
            path: '',
            pathMatch: 'full',
            children: [],
            resolve: {
              LanguageRedirectResolver,
            },
          },
          ...routesThatWereIgnored,
          {
            path: ':lang',
            // this guard check if route has a supported language
            // otherwiser prepend rout with a supported language b4 activate it
            canActivate: [LanguageGuard],

            children: languageUrlRoutesWithoutIgnoredRoutes,
          },
        ] as Routes)
      : combinedRoutes; // if selected language is english return this
  }

  // Helper function for determining panel visibility, now a pure function
  determineAppConfigPanelVisibility(
    baseRoutes: Routes, // hard coded routes
    appConfig: AppConfigService,
  ) {
    if (appConfig.isProdEnv) {
      const newBaseRoutes = [...baseRoutes];
      newBaseRoutes[0].children = newBaseRoutes[0].children.filter(
        (route: Route) => route.path !== 'appconfig-panel',
      );
      return newBaseRoutes;
    }
    return baseRoutes;
  }

  // Your existing helper function for adding child routes, now a pure function
  addChildRoutes(hardRoutes: Routes, cmsRoutes: Routes) {
    return cmsRoutes.length > 0
      ? [
          {
            path: '',
            children: [...cmsRoutes, ...hardRoutes[0].children],
          },
        ]
      : hardRoutes;
  }

  /**
   * Returns the new language path based on the provided URL tree and language settings.
   *
   * @param urlTree - The URL tree representing the current URL.
   * @param supportedLanguages - An array of supported language codes.
   * @param newLang - The new language code to be used.
   * @param filterOutEN - Optional. Specifies whether to filter out the 'en' language code. Default is false.
   * @returns An array of string segments representing the new language path.
   */
  getNewLanguagePath(
    urlTree: UrlTree,
    supportedLanguages: string[],
    newLang: string,
    filterOutEN = false,
  ) {
    const urlSegments = (urlTree.root.children['primary']?.segments || []).map(
      seg => seg.toString(),
    );

    const urlSegmentsContainLanguageCode = supportedLanguages.includes(
      urlSegments[0],
    );

    // Construct the new segments array
    const newSegments = urlSegments.length
      ? [newLang, ...urlSegments.slice(urlSegmentsContainLanguageCode ? 1 : 0)]
      : [newLang];

    const finalSegments = filterOutEN
      ? newSegments.filter(seg => seg !== 'en')
      : newSegments;

    return finalSegments.length ? finalSegments : ['/'];
  }
}
