import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import { AppConfigService } from '@core/app-config/app-config.service';
import { EventsService } from '@core/events/events.service';
import {
  CookieStorageService,
  InjectionTokenIsWebComponent,
  IS_WEB_COMPONENT,
  WINDOW,
} from '@core/index';
import { JbI18nUtilsService, JbLocaleCodes } from 'jb-component-library';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  Observable,
} from 'rxjs';

import { routes } from '../../app.routes';
import { RouterUtilService } from '../../services/router.utils.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public readonly supportedLanguages: string[];
  public readonly holaLanguagesURLs: any;
  public readonly language$: Observable<string>;

  private isHola: boolean;
  private window: Window;
  private cookieService: CookieStorageService;
  private eventsService: EventsService;
  private LANG_MAPPINGS: any;
  private privateLanguage$: BehaviorSubject<string>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(IS_WEB_COMPONENT)
    private isWebComponent: InjectionTokenIsWebComponent,
    private appConfig: AppConfigService,
    private jbI18nUtilsService: JbI18nUtilsService,
    private routerUtilService: RouterUtilService,
    private router: Router,
  ) {
    this.privateLanguage$ = new BehaviorSubject<string>(null);
    // observes when language is changed
    this.language$ = this.privateLanguage$.pipe(
      filter(Boolean),
      distinctUntilChanged(),
    );
    this.LANG_MAPPINGS = {
      en: {
        locale: 'en-US',
        clLocale: JbLocaleCodes['en-US'],
        letters: 'ENG',
      },
      es: {
        locale: 'es-ES',
        clLocale: JbLocaleCodes['es'],
        letters: 'ESP',
      },
      fr: {
        locale: 'fr-FR',
        clLocale: JbLocaleCodes['fr'],
        letters: 'FRA',
      },
    };

    if (isPlatformServer(this.platformId)) {
      // server side code, req for ssr
      // for now on ssr we only focus on English
      this.isHola = false;
      this.supportedLanguages = ['en'];
      this.setLanguage('en');
      return;
    }
    this.window = inject(WINDOW);
    this.cookieService = inject(CookieStorageService);
    this.eventsService = inject(EventsService);
    // to-do move this to detect language and init isHola based on detected language
    this.isHola = this.window.location.hostname.includes('hola');
    this.supportedLanguages = Object.keys(this.appConfig.supportedLanguages);
    this.holaLanguagesURLs = this.appConfig.supportedLanguages;
    this.init();
  }

  init() {
    // at this point router config is not ready;
    // hence we have not done any router navigation
    const defaultLang = this.getDefaultLanguage();

    // we check url for language
    const detected = this.detectLanguage(defaultLang);

    // we need to save the detected language,
    // so angular is able to pick the correct router config
    // see init() in app-routing.module
    this.setLanguage(detected, true);
  }

  public getLanguage() {
    return this.privateLanguage$.getValue();
  }

  public languageToJbLocaleCodes(language: string): JbLocaleCodes {
    return this.LANG_MAPPINGS[language]?.clLocale ?? JbLocaleCodes['en-US'];
  }

  public getLocale(): string {
    // used in analytics service. Can they be happy with 2 letter?
    return this.LANG_MAPPINGS[this.getLanguage()]?.locale;
  }

  public getLanguage3Letter(): string {
    // used in analytics service. Can they be happy with 2 letter?
    return this.LANG_MAPPINGS[this.getLanguage()]?.letters;
  }

  // this is  invoked from the constructor or footer
  // isInitialLoad is true only when called from constructor
  // language is the default language from the cookie, browser, the detected language from the url
  // or the language provided by the footer control
  public switchLanguage(language: string, isInitialLoad = false) {
    if (isPlatformServer(this.platformId)) {
      // server side code, req for ssr
      if (this.getLanguage() !== language) {
        this.setLanguage(language);
      }
      return;
    }
    // no need to switch to same language
    const currentLang = this.getLanguage();
    if (currentLang === language) {
      return;
    }

    // todo: Remove Logic when MP is off
    // from hola or to hola
    // to-do remove isHola otherwise if isHola is true will prevent user from swithcing out from spanish
    if (!this.appConfig.disableHola && (language === 'es' || this.isHola)) {
      const currentUrl = new URL(this.window.location.href);
      const baseURL = this.holaLanguagesURLs[language].replace(/\/$/, '');
      // when navigating to MP we don't want to keep the language in the URL
      const firstUrlSegment = currentUrl.pathname.split('/')[1];
      const pathname = this.supportedLanguages.includes(firstUrlSegment)
        ? currentUrl.pathname.replace(`/${firstUrlSegment}`, '')
        : currentUrl.pathname;

      // to-do replace with setLanguage - cookie should be set only from setLanguage
      this.cookieService.setCookie('lang', language);
      this.window.location.href = `${currentUrl.protocol}//${baseURL}${pathname}${currentUrl.search}${currentUrl.hash}`;
      return;
    }

    this.setLanguage(language, isInitialLoad);
  }

  // set language is only invoked from switchLanguage
  // language is the default language from the cookie, browser, the detected language from the url
  // or the language provided by the footer control
  // in all those cases language is a supported language
  public setLanguage(language: string, isInitialLoad = false) {
    if (isPlatformServer(this.platformId)) {
      // server side code, req for ssr
      this.jbI18nUtilsService.setLocale(this.languageToJbLocaleCodes(language));
      this.privateLanguage$.next(language);
      return;
    }
    // in hola there is only one language
    // to-do this will prevent user from switching out from spanish
    language = this.isHola ? 'es' : language;

    this.jbI18nUtilsService.setLocale(this.languageToJbLocaleCodes(language));

    if (this.isWebComponent && !isInitialLoad) {
      this.eventsService.dispatchCustomEvent(
        EventsService.CUSTOM_EVENTS.JB_LANGUAGE_CHANGE_OUTPUT_EVENT,
        { language: language },
      );
      return;
    }

    this.cookieService.setCookie('lang', language);
    this.window.document.documentElement.setAttribute('lang', language);
    if (!isInitialLoad && !this.isHola) {
      // this is only executed when setLanguage is called from
      // switchLanguage
      // to-do move ot this from set language this should be invoked directly from
      // switchLanguage after setLanguage is invoked.
      this.updateAndNavigateLanguagePath(language);
    }
    this.privateLanguage$.next(language);
  }

  /**
   * Updates the routes and navigates to a new language path if necessary.
   *
   * @param language - The language to update the routes and navigate to.
   * @notes This can be moved out of the service, say into (maybe) app-routing.module.ts
   *       listing for the language change event and updating the routes and navigating
   *       but it is here for now to keep the language switch logic in one place.
   */
  // this is invoked only when we switch to a new Language from the footer
  // pick a new router configuration and redirects url to correct language
  public updateAndNavigateLanguagePath(language: string) {
    if (
      this.appConfig.parsedSitemap && // AppRoutingModule has to parse the sitemap first
      this.appConfig.languageInUrlEnabled &&
      // to-do check why to invoke useLanguageInUrl if language is not english
      // useLanguageInUrl should always be true so checking language !== en is enough
      (this.appConfig.useLanguageInUrl(language) || language === 'en')
    ) {
      // updates the routes with language param support in non-english cases
      // after we switch to new language it reconfigures the router
      // injects language guard and resolver to validate new language in urls
      // to-do remove generateRoutes from here
      // router configuration should only be reset from app-routing.module
      const generatedRoutes = this.routerUtilService.generateRoutes(
        this.appConfig.parsedSitemap,
        routes,
        this.appConfig,
        language,
      );
      this.router.resetConfig(generatedRoutes);
      const newPath = this.routerUtilService.getNewLanguagePath(
        this.router.parseUrl(this.router.url),
        this.supportedLanguages,
        language,
        true,
      );
      this.router.navigate(newPath);
    }
  }

  // we invoke this function when we init LanguageService
  detectLanguage = (defaultLang: string): string => {
    if (!this.appConfig.languageInUrlEnabled) {
      // no need to check url
      return defaultLang;
    }

    // we need to check lang in URL.
    const pathname = this.window.location.pathname;
    const lang = this.detectURLLanguage(pathname);

    // currently the only valid languages in a URL are es & fr;
    if (lang && lang !== 'en') {
      if (this.supportedLanguages.indexOf(lang) !== -1) {
        return lang; // new user preferred lang
      }
    }

    // lang not detected nor supported
    return defaultLang;
  };

  // resolves JetBlue default language
  getDefaultLanguage = (): string => {
    // the preferred browser language from browser settings
    const browserLang = this.window.navigator.language
      .toLowerCase()
      .substring(0, 2);

    // the preferred language previously selected by user
    const cookieLang = this.cookieService.getCookie('lang');

    if (cookieLang) {
      // cookieLang is always a supported language
      return cookieLang;
    }
    // cookieLang not found, check browser preferred language
    if (this.supportedLanguages.indexOf(browserLang) !== -1) {
      return browserLang;
    }
    return 'en'; // JetBlue Default language is english
  };

  detectURLLanguage = (url: string) => {
    // regex matches lang in url if defined.
    // e.g. /es/home or /es or /es?query matches 'es'
    // while /, /home, etc  ... returns null
    const urlMatch =
      /^\/([a-z]{2})$/i.exec(url) || /^\/([a-z]{2})[\/\?\#]/i.exec(url);
    if (urlMatch !== null) {
      // lang detected in url
      return urlMatch[1].toLocaleLowerCase();
    }
    return '';
  };

  // this is prepending the selected language to the current url
  getI18NRedirectURL(
    urlSegments: string[],
    langSegment: string,
    queryParams: Params,
    lang: string,
  ): UrlTree {
    // greater than is fine for now, ideally we have a full language map or something to be explicit
    const langParamIsUrlSegment = langSegment.length > 2;

    const newURLSegments = [lang].concat(
      langParamIsUrlSegment ? [langSegment] : [],
      urlSegments.slice(1),
    );

    // redirect URL
    const newURL = this.router.parseUrl(newURLSegments.join('/'));
    newURL.queryParams = queryParams;
    return newURL;
  }
}
