import { BookerResponse } from './types';

export const MOCK_BOOKER_RESPONSE: BookerResponse = {
  dateAggregator: {
    roundtripPricing: {
      autoLoad: false,
      autoLoadDelay: 1000,
      autoLoadPercentInView: 0.5,
      autoLoadRootMargin: '',
      pointsUrlPointer: 'legacy',
      cashUrlPointer: 'legacy',
      roundTripDefaultTripLength: '7',
      roundTripServiceSuccessCashDepart:
        'Showing per person, lowest recent fares for [TRIP-LENGTH] day trips.',
      roundTripServiceSuccessCashReturn:
        'Showing per person, lowest recent fares for trips departing [DEPART-DATE].',
      roundTripServiceSuccessPointsDepart:
        'Showing per person, lowest recent fares plus taxes & fees for [TRIP-LENGTH] day trips.',
      roundTripServiceSuccessPointsReturn:
        'Showing per person, lowest recent fares plus taxes & fees from $5.60 for trips departing [DEPART-DATE].',
      showCalendarRoundtripPrices: false,
      removeLoadOverlay: false,
      removeLoadOverlayLoadingString: '',
    },
    dateacaaMessage: 'Please press down arrow key to interact with calendar',
    departureLabel: 'Depart',
    returnLabel: 'Return',
    doneLabel: 'Done',
    resetLabel: 'Reset',
    errors: {
      afterMaxDateError: 'Choose date before',
      inValidDateError: 'Please enter a valid date.',
      returnBeforeDepartError: 'Return must be after the depart date',
      genericDateError: 'Please enter a date.',
      beforeMinDateError: 'Choose date after',
    },
    states: {
      serviceLoading: 'Checking schedule..',
      serviceSuccessNoFlights: 'Sorry No flights',
      serviceSuccessCash:
        'One-way, per person, lowest fares shown, incl. taxes & fees.',
      serviceFail: "We couldn't find any flight for this date ",
      content: '<strong>Sorry No flights</strong>',
      serviceSuccessPoints:
        'One-way, per person, lowest fares shown, not incl. taxes & fees.',
    },
  },
  travelSelector: {
    kids: 'Children',
    adult: 'Adult',
    age: 'Age',
    yearAbbreviation: 'yr',
    flightZeroPassengers: 'Please select a passenger.',
    kid: 'Child',
    lapInfant: 'Lap Infant',
    underage: 'Under',
    room: 'Room',
    travelers: 'Travelers',
    rooms: 'Rooms',
    adults: 'Adults',
    children: { title: 'Children', subtitle: '(Under 18)' },
    lapInfants: { title: 'Lap Infants', subtitle: '(Under 2)' },
    errors: {
      noPassengerError: 'Please select a passenger.',
      maxPassengerError: 'Maximum number of passengers selected.',
      invalidChildAge: "Child's age is required",
    },
    rules: [
      {
        label: 'Max-travelers',
        id: 'MAX-TRAVELERS',
        copy: 'Maximum 7 travelers',
      },
      {
        label: 'MAX-Children',
        id: 'MAX-CHILDREN',
        copy: 'Maximum 3 children without an adult.',
      },
      {
        label: 'Max-infants',
        id: 'MAX-INFANTS',
        copy: 'Maximum 3 lap infants, one per adult.',
      },
    ],
    vacation_rules: [
      {
        id: 'MAX-ROOMS',
        label: 'Max-rooms',
        copy: 'Maximum 7 rooms.',
      },
      {
        id: 'MAX-TRAVELERS',
        label: 'Max-travelers',
        copy: 'Maximum 7 travelers.',
      },
      {
        id: 'MAX-CHILDREN',
        label: 'Max-children',
        copy: 'Maximum 6 children.',
      },
      {
        id: 'MAX-INFANTS',
        label: 'Max-infants',
        copy: 'Maximum 3 lap infants, one per adult.',
      },
      {
        id: 'MIN-ADULTS',
        label: 'Min-adults',
        copy: 'Minimum 1 adult per room. ',
      },
      {
        id: 'BEST-RESULTS',
        label: 'Best-results',
        copy: 'For best results, maximum 4 travelers per room.',
      },
    ],
    help: {
      content:
        '<div style="text-align:justify">Need <a href="http://www.jetblue.com/at-the-airport/accessibility-assistance" target="_self">special assistance</a>?</div>\n<br />\nNeed help with <a href="http://www.jetblue.com/travel/groups/" target="_blank">8+ travelers </a>, <a href="https://www.jetblue.com/travel/pets/" target="_blank">pets </a>, <a href="http://www.jetblue.com/travel/kids/" target="_blank">kids or lap infants </a>?',
    },
  },
  citySelector: {
    valueType: 'browseByRegions',
    browseByRegions: 'Browse By Regions',
    swapLabel: 'Reverse origin and destination city or airport',
    from: {
      label: 'From',
      placeholder: 'Where from?',
      message: 'use arrow keys to select drop menu options.',
    },
    to: {
      label: 'To',
      placeholder: 'Where to?',
      message: 'use arrow keys to select drop menu options',
    },
    citySelector: {
      recentSearchesHeading: 'RECENT',
      nearbyHeading: 'NEARBY',
      errorMessage: 'Please enter a valid city.',
      noMatchText: {
        invalidAirport:
          'Hmmm... we couldn\'t find any results for "[SEARCHSTRING]". You can also browse alphabetically.',
        invalidRoute:
          "Uh-oh. It doesn't look like we fly between those two locations. Please try another city nearby.",
      },
      regionsCopy: {
        allRegionsTab: 'All Airports',
        regions: 'Regions',
        title: 'Select a region and city below',
        cities: 'Cities',
        openRegions: 'Browse by regions',
      },
      faresaleCopy: {
        faresaletitle: 'All origins on sale',
        placeholder: 'Where from?',
        nearbyFareSale: 'NEARBY',
        anywhere: 'Anywhere',
        viewalldeals: 'View All Deals',
      },
    },
    legends: [
      {
        title: 'Mint Service',
        code: 'MINT',
        color: 'fill-mint-green',
        image: {
          src: 'https://www.jetblue.com/magnoliapublic/dam/ui-assets/icons/mintIndicator.svg',
          alt: 'Mint Service',
        },
      },
      {
        title: 'Partner Airline',
        code: 'PARTNER',
        color: 'fill-slate-gray',
        image: {
          src: 'https://www.jetblue.com/magnoliapublic/dam/ui-assets/icons/partnerAirlineIndicator.svg',
          alt: 'Partner Airline',
        },
      },
    ],
    enableFuzzySearch: 'false',
  },
  separatePartnerCities: true,
  bookMessages: {
    seasonalRouteMessage: 'The route you’ve selected only operates seasonally.',
    bookWithPoints: 'Use TrueBlue points',
    submitButtonVacations: 'Search vacations',
    roundTrip: 'Roundtrip',
    booker: 'Fly',
    oneWay: 'One-way',
    submitButtonAir: 'Search flights',
    multicity: {
      href: 'https://bookqa.jetblue.com/B6/webqtrip.html?journeySpan=MC',
      title: 'Multi-city',
    },
    servicesFees: { title: 'Search vacations' },
  },
  errorMessages: {
    tooManyLapInfants: 'Please select more adults or fewer infants.',
    minorAgeRestriction: '(age must be between 2 to 18)',
    tooManyPassengers: 'Please select fewer passengers. The limit is 7.',
    daysOfWeekItinerary:
      "The route you selected does not operate every day of the week.\\nIf you are flexible and want to see a full calendar view of our fares, check out the <a href='//www.jetblue.com/best-fare-finder'>Best Fare Finder</a> or click continue.",
    genericCityError: 'Please enter a valid city.',
    returnBeforeDepartError: 'Return must be after the depart date',
    arrivalCityError: 'Please enter a valid city.',
    invalidChildAge: 'Please enter an age for child.',
    seasonalItinerary:
      "The route you selected only operates seasonally.\\nIf you are flexible with your dates and want to see a full calendar view of our fares, check out the <a href='//www.jetblue.com/best-fare-finder'>Best Fare Finder</a> or click continue.",
    beforeMinDateError: 'Choose date after',
    afterMaxDateError: "Choose date before'",
    inValidDateError: 'Please enter a valid date.',
    noPassengerError: 'Please select a passenger.',
    genericDateError: 'Please enter a date.',
    interlinePoints:
      'TrueBlue points cannot be used to search for flights operated by our partner airlines. Please select dollars as your fare type to continue.',
    tooManyMinors: 'A maximum of 3 unnaccompanied minors is allowed.',
    departCityError: 'Please enter a valid city.',
    captchaInvalid: 'Please verify that you are not a robot',
  },
  promoCode: {
    desktopPlaceholder: 'Have a code',
    label: 'Promo code',
    mobilePlaceholder: 'Promo code',
  },
  showVacationsPromoCode: 'false',
  showAirPromoCode: 'false',
  warningMessages: {
    cubaVacation: {
      title: 'Ack!',
      content:
        'Vacation packages (flights + hotel) are not currently available for Cuba. To book flights to Cuba, please start your booking from the Flights tab. To book flights + hotel to somewhere equally awesome, please select another destination. ',
    },
    ptpWarning: {
      title: 'Traveling from Pointe-à-Pitre, Guadeloupe?',
      content:
        'For flights originating in Pointe-à-Pitre, Guadeloupe (PTP), please book through our partner.',
      button: {
        href: 'https://booking.jetblue.com/home/?refid=8382',
        newWindow: '_blank',
        title: 'Book with our partner',
      },
    },
    todaysDate: {
      title: 'Just checking: You’d like to fly today?',
      content: `OK, we’ll only show you flights that leave at least 90 minutes from the current time.`,
    },
    sameDayVacation: {
      title: 'A same-day vacation? That’s not really a vacation.',
      content: `You’ve selected a return date that’s the same day as your departure. To complete this booking (and enjoy a longer stay) please select a different return date.`,
    },
    shortTrip: {
      title: '',
      content:
        'Your return date is the same as your departure date. Is such a short trip intentional?',
    },
    longTrip: {
      title: '',
      content:
        'You have selected a return date more than 4 weeks after your departure date. Is this intentional?',
    },
    owInternational: {
      title: 'You may need proof of return travel',
      content:
        'Proof of return travel is often a requirement for one-way international flights like this. Please check with your destination country’s embassy to confirm their specific requirements',
    },
    unnacompaniedMinor: {
      title: 'Unaccompanied Minors',
      content:
        'Children between the ages of 5 and under 14 years who will be traveling alone are considered unaccompanied minors. Unaccompanied minors may only travel on nonstop flights. They cannot travel on connecting flights or direct flights (flights that make a stop, but do not change aircraft), or multi-city itineraries. Flights booked are subject to a $100 fee each way per unaccompanied minor. Once booked, please fill out the form from the link on your confirmation page, print 3 copies and bring them with you to the airport.',
    },
    bothInternationalFlights: {
      title: 'Looks like you’ll make a stop in the U.S',
      content:
        'International travelers connecting through to a U.S. airport are required to have a valid passport and visa for the U.S., and may need a visa for the final destination. You may also need to claim your checked bags upon arriving in the U.S. and recheck them to your final destination.',
    },
    invalidFlightSearch: {
      title: 'Looks like you’ll make a stop in the U.S',
      content:
        'International travelers connecting through to a U.S. airport are required to have a valid passport and visa for the U.S., and may need a visa for the final destination. You may also need to claim your checked bags upon arriving in the U.S. and recheck them to your final destination.',
    },
    airSearch: 'Continue to flight results',
    airContinue: 'Continue',
    airCancel: 'Cancel',
    vacationsOk: 'Ok',
  },
  opacity: '1',
  bgColor: 'bg-white',
  removeRouteFiltering: 'true',
  showShortCityName: 'true',
  keepCalendarOpen: 'false',
  vacationsLink: {
    descriptionText: 'test Jira 113',
    enableLink: 'true',
    linkLabel: 'test 113',
  },
};
