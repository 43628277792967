import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CONFIG_AUTH_IS_IDX_LOCALSTORAGE } from '@core/app-config/types/mocks/app-config-constants';
import {
  AppConfigService,
  CookieStorageService,
  HttpService,
  LocalStorageService,
  RouterService,
  WidgetService,
  WINDOW,
  WindowService,
} from '@core/index';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { IdxTransaction, Tokens } from '@okta/okta-auth-js';
import { SITEMAP_DICTIONARY } from '@shared/ui/links/linkers/sitemap-dictionary.token';
import { registerLogFullStory } from '@shared/ui/utils/global-utils/full-story';
import { CartFacade } from '@store/cart/cart.facade';
import { GetMemberPayment } from '@store/preference-center/preference-center.actions';
import { ProfileApi } from '@store/profile/api/profile.api';
import { RWBProfile } from '@store/profile/types/profile-details-response.interface';
import { createErrorAction } from '@store/shared/action.utils';
import { StoreService } from '@store/shared/store.service';
import getTime from 'date-fns/getTime';
import { combineLatest, from, Observable, of, timer } from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  delayWhen,
  filter,
  first,
  map,
  mergeMap,
  share,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { RouterFacade } from '../router/router.facade';
import { AuthApi } from './api/auth.api';
import { AuthTimerService } from './api/auth-timer.service';
import { OktaService } from './api/okta.service';
import {
  AuthActions,
  LeanProfileFailed,
  LeanProfilePending,
  LeanProfileSuceeded,
  LoginUserOkta,
  OktaClassicRedirect,
  OktaIdxRedirect,
  OktaRedirectToLogoutPage,
  OktaRenewToken,
  OktaRenewTokenFailure,
  OktaRenewTokenSuccess,
  OktaSetToken,
  StartLogoutUserOktaFlow,
  UserLoggedInOktaFlow,
  UserPostLoginFlow,
  UserTermsAndConditionsFlow,
} from './auth.actions';
import { AuthFacade } from './auth.facade';
import { mapLeanProfileToExistingProfile } from './auth.utils';
import { LeanProfile, OktaAccessTokenEntry } from './types';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authApi: AuthApi,
    private authFacade: AuthFacade,
    private routerFacade: RouterFacade,
    private cookieStorageService: CookieStorageService,
    @Inject(WINDOW) private window: any,
    private windowService: WindowService,
    private appConfig: AppConfigService,
    private oktaService: OktaService,
    private authTimerService: AuthTimerService,
    private widgetService: WidgetService,
    private routerService: RouterService,
    private storeService: StoreService,
    private localStorageService: LocalStorageService,
    private cartFacade: CartFacade,
    private profileApi: ProfileApi,
    @Inject(SITEMAP_DICTIONARY) @Optional() private sitemapDictionary,
  ) {}

  // SPIKE DOT-10775 - Is this auth Effect is still in use?
  authProfileOnInit$ = this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    switchMap(() => {
      return this.authFacade.accessToken.pipe(
        filter<OktaAccessTokenEntry>(Boolean),
        first(),
      );
    }),
    share(),
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  logoutOktaFailure$ = this.actions$.pipe(
    ofType<OktaClassicRedirect>(AuthActions.OKTA_USER_LOGGED_OUT_FAILURE),
    share(),
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  oktaRenewToken$ = this.actions$.pipe(
    ofType<OktaRenewToken>(AuthActions.OKTA_RENEW_TOKEN),
    share(),
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  userActionIdled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.USER_SESSION_IDLED),
        tap(() => {
          this.oktaService.unbindTokenExpiryListener();
          this.authTimerService.stopAuthTimer();
          this.authTimerService.subscribeUserEvents();
        }),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  userSessionCheckRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.USER_SESSION_REQUESTED),
        tap(() =>
          this.oktaService
            .checkUserSession()
            .catch(error => console.error(error)),
        ),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  userSessionRewened$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.OKTA_SET_TOKEN_SUCCESS),
        tap(() => {
          this.oktaService.bindTokenExpiryListener();
          this.authTimerService.unsubscribeUserEvents();
          this.authTimerService.startAuthTimer();
        }),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  userSessionExpired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.OKTA_SET_TOKEN_FAILURE),
      map(() => {
        this.authTimerService.stopAuthTimer();
        this.authTimerService.unsubscribeUserEvents();
        this.authApi.deleteAuthCookies();
        this.authApi.deleteAuthLocalStorageItems();
        this.authApi.logout();
        this.oktaService.clearToken();
        this.cartFacade.dispatchClearCartAction();
        this.window.location.reload();
        return new OktaRenewTokenFailure('NoSession');
      }),
    ),
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  loadProfileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LEAN_PROFILE_REQUESTED),
      mergeMap(({ accessToken }) => {
        // Fetch Lean Profile
        return this.authApi.requestLeanProfile(accessToken).pipe(
          map(
            (response: LeanProfile) =>
              new LeanProfileSuceeded({
                response,
                authProfile: mapLeanProfileToExistingProfile(response),
                requestToken: accessToken,
              }),
          ),
          catchError(response => of(new LeanProfileFailed(response))),
        );
      }),
    ),
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  getTravelBankBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LeanProfileSuceeded>(AuthActions.LEAN_PROFILE_SUCCEEDED),
      map(({ payload }) => {
        return new GetMemberPayment({
          RequestToken: {
            Token: payload.requestToken,
            LogoutUrl: 'https://www.jetblue.com',
            RetrieveFamilyInformation: true,
          },
        });
      }),
    ),
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  leanProfileLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LeanProfileSuceeded>(AuthActions.LEAN_PROFILE_SUCCEEDED),
        tap(({ payload }) => {
          this.authApi.setCookiesAfterLoginSuccess(payload.authProfile);
          this.cookieStorageService.setIsBirthdayCookie(
            payload.response.birthDate,
          );
        }),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  authorizeUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AUTHORIZE_USER),
        tap(() => this.oktaService.login()),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  profileRequestPending$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.FINISH_POST_LOGIN_SETUP,
        AuthActions.LEAN_PROFILE_REQUESTED,
      ),
      map(() => new LeanProfilePending(true)),
    ),
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  profileRequestCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.LEAN_PROFILE_SUCCEEDED,
        AuthActions.LEAN_PROFILE_FAILED,
      ),
      map(() => new LeanProfilePending(false)),
    ),
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  setToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OktaSetToken>(AuthActions.OKTA_SET_TOKEN),
        mergeMap(action => {
          return this.oktaService.setToken(action.sessionId).pipe(
            tap(success => this.authFacade.setTokenSuccess(success)),
            catchError(error => of(this.authFacade.setTokenFailure(error))),
          );
        }),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  userLoggedOutOktaFailure$ = createEffect(
    () =>
      this.logoutOktaFailure$.pipe(
        map(() => this.windowService.redirectOktaAfterLogout()),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * This event is fired for any OKTA flow.
   * *****
   * @description
   * This function is called right before the access token is expired. From okta.service.ts.
   * There is a event listener from OKTA that will keep tracking the status of the token. Case you wan to test it, go to
   * global-factories and change the value expireEarlySeconds to 890. If you do not do that,
   * you will need to wait 15 min to see this function being called.
   */
  silentRenewToken$ = createEffect(
    () =>
      this.oktaRenewToken$.pipe(
        mergeMap(action => {
          return from(this.oktaService.refreshToken(action.key)).pipe(
            tap(newToken => {
              this.storeService.dispatchAction(
                new OktaRenewTokenSuccess(action.key, {
                  ...newToken,
                  requestedAt: this.oktaService.dateService
                    .getNewDate()
                    .getTime(),
                }),
              );
            }),
            catchError(err => {
              return of(new OktaRenewTokenFailure(err));
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * TO BE DOCUMENTED: DOT-10783
   * @description
   */
  clearPersonalizationsOnSessionTimeout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.OKTA_RENEW_TOKEN_FAILURE),
        tap(() => this.authApi.logout()),
      ),
    { dispatch: false },
  );

  /**
   * *****
   * @eventProperty
   * This event is fired for the following flows:
   * * Classic
   * * Idx
   * *****
   * @description This marks the initial effect that will be called for OKTA flow (When the flag useWidget is false).
   * It will keep listening for two effects: 'loginOktaIdx$' - for idx and 'loginOktaClassic$' - for Classic.
   * However, it remains inactive when the flag 'useWidget' is set to true.
   * When dealing with the OKTA Widget, use the `dot-okta-widget.component.ts` as a reference.
   * The flow when using the Widget is slight different.
   */
  authOktaInit$ = createEffect(
    () => {
      return this.appConfig.useWidget
        ? from([])
        : this.oktaService.isSigningInWithIdx$.pipe(
            switchMap(isSigningInWithIdx => {
              if (isSigningInWithIdx) {
                return this.loginOktaIdx$.pipe(
                  filter(payload => payload.requestDidSucceed),
                  map(payload => this.authFacade.userLoggedInWithOkta(payload)),
                );
              } else {
                return this.loginOktaClassic$.pipe(
                  filter(HttpService.isSuccessful),
                  map(payload => this.authFacade.userLoggedInWithOkta(payload)),
                );
              }
            }),
          );
    },
    { dispatch: false },
  );

  handlePostOktaLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserLoggedInOktaFlow>(AuthActions.OKTA_USER_LOGGED_IN),
      filter(({ payload }) => payload['code'] !== 'JB_INVALID_CREDENTIALS'),
      switchMap(({ payload }) => {
        const accessToken = (payload as any)?.tokens.accessToken.accessToken;

        // Retrieve the current returnUrl from the router state if available
        const currentUrl = this.routerService.currentUrl;
        const returnUrl = new URLSearchParams(currentUrl.split('?')[1]).get(
          'returnUrl',
        );

        return this.profileApi.requestRwbProfile(accessToken).pipe(
          map((response: HttpResponse<any>) => response.body),
          map((profileResponse: RWBProfile) => {
            if (
              !!profileResponse &&
              // if this is false TAC is not accepted yet and a message
              // should be displayed to user asking to accept TAC after login
              !profileResponse?.isTCAccepted &&
              this.appConfig.isTaCEnabled
            ) {
              return {
                type: AuthActions.PROMPT_ACCEPTANCE,
                payload: { id: profileResponse.id, returnUrl },
              };
            } else {
              return {
                type: AuthActions.FINISH_POST_LOGIN_SETUP,
                payload,
              };
            }
          }),
        );
      }),
    ),
  );

  /**
   *  *****
   * @eventProperty
   * This event is fired for the following flows:
   * * Classic
   * * Idx
   * * Widget - useClassicEngine: false
   * * Widget - useClassicEngine: true - To be implemented in the future.
   * *****
   * @description After having a response from authOktaInit$ effect, an the action OKTA_USER_LOGGED_IN will be called to continue the flow.
   * Note: This action may also be called when handling the OKTA Widget.
   */
  continuePostOktaLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserPostLoginFlow>(AuthActions.FINISH_POST_LOGIN_SETUP),
      tap(({ payload }) => {
        /*
         * Only register auth cookies if authentication worked.
         */
        if (payload['code'] !== 'JB_INVALID_CREDENTIALS') {
          this.cookieStorageService.setB6Cookie();
          this.cookieStorageService.setCookie('jbFSIdentify', 'INIT');
        }
      }),
      mergeMap(({ payload }) =>
        this._redirectUserPostOktaOrchestratorCookieSetup(payload),
      ),
    ),
  );

  redirectToTerms$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserTermsAndConditionsFlow>(AuthActions.PROMPT_ACCEPTANCE),
        map(action => action.payload),
        tap(({ id, returnUrl }) => {
          this.routerService.navigate('/signin', {
            queryParams: {
              id,
              useIdx: true,
              // when profileResponse?.isTCAccepted is false display the TAC Component
              // see this.profileApi.requestRwbProfile above
              tacAccept: true,
              returnUrl: returnUrl || '',
            },
          });
        }),
      ),
    { dispatch: false },
  );

  private _redirectUserPostOktaOrchestratorCookieSetup(
    authResponse: {
      tokens?: Tokens;
    } & { sessionToken?: string },
  ) {
    /*
     * This localstorage is really important when handling the logout flow.
     * The logout flow differs between OKTA Classic and Idx.
     * Consequently, it's essential to verify this storage before proceeding with logout.
     * */
    this.localStorageService.setItem(
      CONFIG_AUTH_IS_IDX_LOCALSTORAGE,
      this.oktaService.shouldUseIdx,
    );

    if (this.oktaService.shouldUseIdx) {
      return authResponse.tokens
        ? from([this.authFacade.idxRedirect(authResponse)])
        : from([]);
    }

    return authResponse.sessionToken
      ? from([this.authFacade.sessionCookieRedirect(authResponse.sessionToken)])
      : from([]);
  }

  /**
   *  *****
   * @eventProperty
   * This event is fired for the following flows:
   * * Classic
   * * Idx
   * * Widget - useClassicEngine: true/false
   * *****
   * @description The first Step from Logging Out from OKTA. It will validate if the authentication was made by Idx or Classic.
   * Handling the right logic for each one.
   */
  startLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<StartLogoutUserOktaFlow>(AuthActions.OKTA_START_LOGOUT),
        tap(() => {
          // Get the method that was used for signing in.
          const useIdx: boolean = this.localStorageService.getItem(
            CONFIG_AUTH_IS_IDX_LOCALSTORAGE,
          );
          if (useIdx) {
            return this.oktaService.getUIDAndRevokeIdxToken();
          }
          return this.oktaService.getUIDAndRenewToken();
        }),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * This event is fired for the following flows:
   * * Classic
   * * Idx
   * * Widget - useClassicEngine: true/false
   * *****
   * @description Initiate redirection of the user to the /logout page, where the logout process will proceed.
   */
  redirectToOktaLogoutPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OktaRedirectToLogoutPage>(
          AuthActions.OKTA_REDIRECT_TO_LOGOUT_PAGE,
        ),
        tap(() => {
          this.windowService.redirectToOktaLogoutPage();
        }),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * This event is fired for the following flows:
   * * Classic
   * * Idx
   * * Widget - useClassicEngine: true/false
   * *****
   * @description Call OKTA SDK to clear any token that was stored.
   */
  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.OKTA_USER_LOGGED_OUT_SUCCESS),
        tap(() => {
          this.oktaService.clearToken();
        }),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * This event is fired for the following flows:
   * * Idx
   * * Widget - useClassicEngine: false
   * *****
   * @description Creates an Listener to the action OKTA_IDX_REDIRECT.
   * redirectToAfterIdxLogin$ will be called after it.
   */
  oktaIdxRedirect$ = this.actions$.pipe(
    ofType<OktaIdxRedirect>(AuthActions.OKTA_IDX_REDIRECT),
    share(),
  );

  /**
   * @eventProperty
   * This event is fired for the following flows:
   * Idx
   * Widget - useClassicEngine: false
   * *****
   * @description The last Effect that will be called for Idx.
   * After fetching the OKTA Tokens, register a log on FS, Initialize ASAP and redirect user back to the previous page.
   */
  redirectToAfterIdxLogin$ = createEffect(
    () =>
      this.oktaIdxRedirect$.pipe(
        map(({ payload }) => payload),
        withLatestFrom(this.routerFacade.queryParams),

        tap(([_, queryParams]) => {
          registerLogFullStory(
            'Successful Sign In',
            {
              page_path_str: this.window.location.href,
            },
            this.window,
          );

          this.widgetService.resetASAPPChatSessionStorage();

          // Get return URL and remove the initial / (If any)
          const { returnUrl = '' } = queryParams;

          // Check if the returnUrl is a valid route in our Sitemap.
          if (this.sitemapDictionary.hasOwnProperty(`/${returnUrl}`)) {
            this.routerService.navigate(returnUrl);
          } else {
            let decodedReturnUrl = '';

            /**
             * We have classic and idx modes.
             * In Classic we encode return url with btoa and in idx we don't need it
             * ( its being handled differently by okta) so we don't encode it.
             * dotcom is on idx but digb is not yet so we need to support on login redirect for both cases.
             *
             * One way to to see if the string was encoded is to try decoding it and see if it throws an error.
             *
             * TODO: remove this logic once everyone switches to Idx
             */
            try {
              decodedReturnUrl = atob(returnUrl);
            } catch {
              decodedReturnUrl = returnUrl.startsWith('/')
                ? returnUrl.substring(1)
                : returnUrl;
            }

            // Check in our Sitemap if the returnUrl is a valid route.
            const isDotcomLink = this.sitemapDictionary.hasOwnProperty(
              `/${decodedReturnUrl}`,
            );
            // Internally redirect if redirectURL is a valid DOTCOMLink. otherwise, redirect to outside.
            isDotcomLink
              ? this.routerService.navigate(decodedReturnUrl)
              : this.windowService.navigateToOutsideUrl(decodedReturnUrl);
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * This event is fired for the following flows:
   * * Idx
   * *****
   * @description After entering the username and password, proceed to validate the transaction using OKTA in the okta.service.ts file.
   * OKTA will verify the provided credentials and subsequently provide the transaction status.
   * The result from this action, will be catch at `authOktaInit$`. And the flow will continue from there.
   * Note: Please be aware that this EFFECT will not be triggered if the useWidget flag is enabled.
   */
  loginOktaIdx$: Observable<IdxTransaction> = this.actions$.pipe(
    ofType<LoginUserOkta>(AuthActions.LOGIN_USER_OKTA),
    mergeMap(({ payload }) =>
      from(this.oktaService.loginWithIdx(payload.email, payload.password)),
    ),
    tap(transaction => {
      switch (transaction.status) {
        case 'SUCCESS':
          this.oktaService.setAccessToken(transaction);
          break;
        case 'PENDING':
        case 'FAILURE':
        case 'CANCELED':
        case 'TERMINAL':
          const error = {
            httpStatus: 401,
            code: 'JB_INVALID_CREDENTIALS',
            message: transaction?.messages?.[0]?.message || '',
            guid: '',
          };
          this.storeService.dispatchAction(
            createErrorAction(AuthActions.OKTA_USER_LOGGED_IN, error),
          );
          break;
        default:
          break;
      }
    }),
    share(),
  );

  /**
   * @eventProperty
   * This event is fired for the following flows:
   * * Classic
   * * Widget - useClassicEngine: true
   * *****
   * @description After entering the username and password, the Jetblue Orchestrator API will be called.
   * This orchestrator is an API that will handle the credentials in a security way.
   * Internally, the Orchestrator will call OKTA Auth to handle the credentials,
   * returning the session that will be used in our `/login-callback` page.
   * This is a security Layer in the authentication flow. Controlled by Jetblue Security Team.
   * NOTE: OKTA Widget with useClassicEngine is not currently working for this API.
   * This API is going to be deprecated soon.
   */
  loginOktaClassic$ = this.actions$.pipe(
    ofType<LoginUserOkta>(AuthActions.LOGIN_USER_OKTA),
    mergeMap(({ payload }) => {
      return this.authApi.loginOkta(payload.email, payload.password);
    }),
    share(),
  );

  /**
   * @eventProperty
   * This event is fired for the following flows:
   * * Classic
   * * Widget - To be implemented in the future.
   * *****
   * @description Creates an Listener to the action OKTA_CLASSIC_REDIRECT.
   * redirectToOktaClassicAfterLogin$ will be called after it.
   */
  oktaClassicRedirect$ = this.actions$.pipe(
    ofType<OktaClassicRedirect>(AuthActions.OKTA_CLASSIC_REDIRECT),
    share(),
  );

  /**
   * @eventProperty
   * This event is fired for the following flows:
   * * Classic
   * * Widget - To be implemented in the future.
   * *****
   * @description The final step in the Classic process before guiding the user to the /login-callback page.
   * After fetching the OKTA Session from jetblue Orchestrator API, this method will be called to:
   * Register a log on FS.
   * Initialize ASAP.
   * Redirect user to `/login-callback` with the OKTA Token.
   * The method navigateToOutsideUrl is going to send the user to OKTA side using the URL provided at sessionCookieRedirectUrl.
   * After that, OKTA is going to check the session and it will return the user to the page that was configured
   * in the Okta Factory at `global-factories.ts` - redirectUri.
   */
  redirectToOktaClassicAfterLogin$ = createEffect(
    () =>
      this.oktaClassicRedirect$.pipe(
        map(({ payload }) => payload),
        withLatestFrom(this.routerFacade.queryParams),
        tap(([sessionToken, queryParams]) => {
          registerLogFullStory(
            'Successful Sign In',
            {
              page_path_str: this.window.location.href,
            },
            this.window,
          );

          this.widgetService.resetASAPPChatSessionStorage();
          this.windowService.navigateToOutsideUrl(
            `${
              this.appConfig.sessionCookieRedirectUrl
            }?token=${sessionToken}&redirectUrl=${
              queryParams.returnUrl
                ? queryParams.returnUrl
                : queryParams.RelayState
            }`,
          );
        }),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * This event is fired for the following flows:
   * * Idx
   * * Widget - useClassicEngine: false
   * *****
   * @description This Effect will be triggered in the last flow of signing out Idx.
   */
  logoutUserOktaIdxFlow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.OKTA_START_LOGGING_OUT_IDX),
        switchMap(() => {
          let delayTime;
          let startTime;
          return of({}).pipe(
            // get time now.. This will be used in the end of this logic
            tap(() => (startTime = getTime(new Date()))),
            // end session for pre configured domains
            mergeMap(() => this.authApi.sessionEndJbdomains()),
            // delete auth localStorage items
            tap(() => this.authApi.deleteAuthLocalStorageItems()),
            // delete auth sessionStorage items
            tap(() => this.authApi.deleteAuthSessionStorageItems()),
            // delete auth cookies items
            tap(() => this.authApi.logout()),
            // reset store to it's initial state upon success
            tap(() => this.authFacade.userLoggedOutOktaSuccess()),
            // The delay time is required to close all sessions that were opened by iFrames.
            tap(() => {
              delayTime = this.oktaService.isLogoutWithIframes
                ? Math.max(
                    0,
                    this.appConfig.oktaLogoutDelayTime -
                      (getTime(new Date()) - startTime),
                  )
                : 0;
            }),
            // Hold the code for a while just to close all sessions.
            delayWhen(() => timer(delayTime)),
            // Wait access token to be cleared from our Store before proceeding. This will a bug to hit sign out twice. Ref: DOT-11034
            switchMap(() =>
              this.authFacade.accessToken.pipe(
                filter(accessToken => !accessToken?.accessToken),
                take(1),
                defaultIfEmpty(null),
              ),
            ),
            tap(() => {
              this.windowService.redirectOktaAfterLogout();
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  /**
   * @eventProperty
   * This event is fired for the following flows:
   * * Classic
   * * Widget - useClassicEngine: true.
   * *****
   * @description This Effect will be triggered in the last flow of signing out from CLASSIC.
   */
  logoutUserOktaClassicFlow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.OKTA_START_LOGGING_OUT_CLASSIC),
        mergeMap(() =>
          combineLatest([this.authFacade.uid, this.authFacade.accessToken]),
        ),
        filter(([uid]) => !!uid),
        switchMap(([uid, token]) => {
          let delayTime;
          let startTime;
          return this.authApi
            .deleteOrchestrator({ uid, token: token.accessToken })
            .pipe(
              filter(res => {
                return !res || (res && !res.error);
              }),
              // get time now.. This will be used in the end of this logic
              tap(() => (startTime = getTime(new Date()))),
              // end session for pre configured domains
              mergeMap(() => this.authApi.sessionEndJbdomains()),
              // delete auth localStorage items
              tap(() => this.authApi.deleteAuthLocalStorageItems()),
              tap(() => this.authApi.logout()),
              // reset store to it's initial state upon success
              tap(() => this.authFacade.userLoggedOutOktaSuccess()),
              // The delay time is required to close all sessions that were opened by iFrames.
              tap(() => {
                delayTime = this.oktaService.isLogoutWithIframes
                  ? Math.max(
                      0,
                      this.appConfig.oktaLogoutDelayTime -
                        (getTime(new Date()) - startTime),
                    )
                  : 0;
              }),
              // Hold the code for a while just to close all sessions.
              delayWhen(() => {
                return timer(delayTime);
              }),
              // Wait access token to be cleared from our Store before proceeding. This will a bug to hit sign out twice. Ref: DOT-11034
              switchMap(() =>
                this.authFacade.accessToken.pipe(
                  filter(accessToken => !accessToken?.accessToken),
                ),
              ),
              // redirect user to return url after successfull logout
              tap(() => this.windowService.redirectOktaAfterLogout()),
            );
        }),
      ),
    { dispatch: false },
  );
}
