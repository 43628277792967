import { Routes } from '@angular/router';


export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'best-fare-finder',
        data: {
          path: '/best-fare-finder',
          template: 'best-fare-finder',
          preload: false,
        },
        loadChildren: () =>
          import('./feature/bff/bff.module').then(m => m.BffModule),
      },
      {
        path: 'destinations',
        data: {
          path: '/destinations',
          template: 'destinations',
          preload: false
        },
        loadChildren: () =>
          import('./feature/flights-destination/flights-destination.module').then(m => m.FlightsDestinationModule),
      },
      {
        path: 'route-map',
        data: {
          path: '/route-map',
          template: 'route-map',
          preload: false
        },
        loadChildren: () =>
          import('./dynamic-components/route-map/route-map.module').then(m => m.RouteMapModule),
      },
      {
        path: 'appconfig-panel',
        data: {
          path: '/appconfig-panel',
          template: 'appconfig-panel',
          preload: false,
        },
        loadChildren: () =>
          import('./feature/appconfig-panel/appconfig-panel.module').then(m => m.AppconfigPanelModule),
      },
      {
        path: 'preference-center',
        data: {
          path: '/preference-center',
          template: 'preference-center',
          preload: false
        },
        loadChildren: () =>
          import('./feature/preference-center/preference-center.module').then(m => m.PreferenceCenterModule),
      },
      {
        path: 'logout',
        data: {
          path: '/logout',
          template: 'okta-logout',
          preload: false,
        },
        loadChildren: () =>
          import('./feature/okta-logout/okta-logout.module').then(m => m.OktaLogoutModule),
      },
      {
        path: 'login-callback',
        data: {
          path: '/login-callback',
          template: 'okta-logout',
          preload: false,
        },
        loadChildren: () =>
          import('./feature/okta-login-callback/okta-login-callback.module').then(m => m.OktaLoginCallbackModule),
      },
    ],
  },
  // to-do remove this is added in generateRoutes()
  // {
  //  path: '**',
  //  component: NotFoundPageComponent,
  //  data: { pageName: 'Page Not Found' },
  // },
];

export const devRoutes: Routes = [{
    path: 'booker-v2',
    data: {
      path: '/booker-v2',
      template: 'best-fare-finder',
      preload: false,
    },
    loadChildren: () =>
      import('./dynamic-components/booker-v2/shared/booker-v2-page/booker-v2-page.module').then(m => m.BookerV2PageModule),
}];

function flattenBaseRoutes(baseRoutes) {
  return baseRoutes
    .filter(route => route.path !== '**')
    .reduce(
      (acc, route) =>
        route.children
          ? { ...acc, ['/']: 'main', ...flattenBaseRoutes(route.children) }
          : { ...acc, [`/${route.path}`]: route.data.template || 'base' },
      {},
    );
}

export const baseRouteDictionary = flattenBaseRoutes(routes);

// to-do remove no one calls this function
export function baseRouteDictionaryFactory() {
  return baseRouteDictionary;
}
