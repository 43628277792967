import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { LanguageService } from '@core/language/language.service';

@Injectable({ providedIn: 'root' })
export class LanguageGuard implements CanActivate {
  constructor(private languageService: LanguageService) {}

  // this guard is injected in router config in generateRoutes()
  // this runs only after the router configuration is ready
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    // by the time this language guard is running language service
    // already detected selected language via constructor or switchLanguage
    // and saved in cookie.
    const cookieLang = this.languageService.getLanguage();
    const langSegment = route.params['lang'];

    // currently the only valid languages in a URL are es & fr;
    // language in URL & cookie must agree if they are supported language
    // since LanguageService validate URL and set cookie
    if (langSegment !== 'en' && langSegment === cookieLang) {
      return true;
    }
    // we get here if the language in URL is not supported
    // this block will remove it and prepend current selected language from cookie into URL
    const lang = cookieLang === 'en' ? '' : cookieLang; // en is not supported in URL
    const urlSegments = state.url.split('/');
    const queryParams = route.queryParams;

    // cancel navigation and redirects to new URL.
    return this.languageService.getI18NRedirectURL(
      urlSegments.filter(Boolean),
      langSegment,
      queryParams,
      lang,
    );
  }
}
