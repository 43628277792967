import { isPlatformServer } from '@angular/common';
import { Inject, inject, NgModule, PLATFORM_ID } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { LanguageService } from '@core/language/language.service';
import { CustomPreloadingService } from '@core/preloading-strategy/custom-preloading.service';

import { devRoutes, routes } from './app.routes';
import { AppConfigService, CMSSiteMap, INITIAL_SITEMAP } from './core';
import { parseSitemap } from './core/cms/api-transform/cms-sitemap.api-transform';
import { CMSSitemapWrapper } from './core/cms/types';
import { RouterUtilService } from './services/router.utils.service';

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: CustomPreloadingService,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  router: Router;
  initialSitemap: CMSSitemapWrapper;
  appConfig: AppConfigService;
  languageService: LanguageService;
  routerUtilService: RouterUtilService;
  constructor(@Inject(PLATFORM_ID) private platformId) {
    if (isPlatformServer(platformId)) {
      return;
    }
    this.router = inject(Router);
    this.initialSitemap = inject(INITIAL_SITEMAP);
    this.appConfig = inject(AppConfigService);
    this.languageService = inject(LanguageService);
    this.routerUtilService = inject(RouterUtilService);
    this.init();
  }

  init() {
    try {
      const sitemap = this.removeSiteMapPaths(this.initialSitemap.sitemap);
      const parsedSitemap = parseSitemap(sitemap);

      this.appConfig.parsedSitemap = parsedSitemap;

      this.addRoutes(
        parsedSitemap,
        routes,
        this.appConfig.languageInUrlEnabled
          ? this.languageService.getLanguage()
          : 'en',
        // forces the /en/ route to also work - this is only necessary when the app loads with /en/ in the ur;
        this.languageService.getLanguage() === 'en',
      );
    } catch (e) {
      console.error('Sitemap failed to parse', e);
    }
  }

  addRoutes(
    cmsRoutes: Routes,
    baseRoutes: Routes, // hard coded routes
    language = 'en',
    forceEn = false,
  ) {
    const finalRoutes = this.routerUtilService.generateRoutes(
      cmsRoutes,
      baseRoutes,
      this.appConfig,
      language,
      devRoutes,
      forceEn,
    );
    this.router.resetConfig(finalRoutes);
  }

  // Removes any path related to manage-trips from our SiteMap.
  // removes /fr from the sitemap path
  // just in case Motion Point accidentally adds it to the sitemap
  removeSiteMapPaths(sitemap: CMSSiteMap[]): CMSSiteMap[] {
    try {
      // If the following flags are not enabled, return the original sitemap.
      if (this.appConfig.blackListSitemapPaths?.length === 0) {
        return sitemap;
      }

      let path: string;
      return sitemap.map(sm => {
        path = sm.path;
        // Remove from the sitemap any routes in the blacklist
        if (
          this.appConfig.blackListSitemapPaths.some(
            blackListPath => blackListPath === path,
          )
        ) {
          path = '';
        }

        return {
          ...sm,
          path,
          children:
            sm?.children?.length > 0
              ? this.removeSiteMapPaths(sm.children)
              : sm?.children,
        };
      });
    } catch (_) {
      return sitemap;
    }
  }
}
