import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService, HttpService } from '@core/index';
import { BookProfile } from '@store/cart/types/book-profile.interface';
import { ProfileActions } from '@store/profile/profile.actions';
import { createErrorAction, networkError } from '@store/shared/action.utils';
import { StoreService } from '@store/shared/store.service';
import { NetworkError } from '@store/shared/types/network-error.type';
import { Observable, of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AcceptTACResp } from 'src/app/shared/dot-tac-accept/types/dot-tac-accept.interface';
import { v4 as uuidV4 } from 'uuid';

const TAC_ENDPOINT = '/accept-terms-and-conditions';
const REQUEST_ID_HEADER = 'x-dotcom-request-id';

@Injectable({ providedIn: 'root' })
export class ProfileApi {
  constructor(
    private http: HttpService,
    private storeService: StoreService,
    private appConfig: AppConfigService,
  ) {}

  requestRwbProfile(
    accessToken: string,
    xAuthToken?: string,
  ): Observable<HttpResponse<BookProfile> | NetworkError> {
    const url = this.appConfig.rwbProfileUrl;
    const body: any = { jwt: accessToken };
    let headers;

    if (!xAuthToken) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'API-Version': this.appConfig.shoppingCartConfig.apiVersion,
      });
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'API-Version': this.appConfig.shoppingCartConfig.apiVersion,
        'x-auth-token': xAuthToken,
      });
    }

    return this.http
      .postObserveResponse<BookProfile>(url, body, { headers })
      .pipe(
        catchError(response => {
          this.storeService.dispatchAction(
            createErrorAction(ProfileActions.RWB_PROFILE_FAILED, response),
          );
          return observableOf(networkError());
        }),
      );
  }

  updateProfileTAC(): Observable<HttpResponse<AcceptTACResp>> {
    const params = new URLSearchParams(window.location.search);
    const headers = new HttpHeaders().set(REQUEST_ID_HEADER, uuidV4());

    return this.http.postObserveResponse<AcceptTACResp>(
      TAC_ENDPOINT,
      { id: params.get('id') },
      { headers },
    );
  }
}
